import { BottomSheet } from '@/components/BottomSheet'
import { eventBus, useEventBusEffect } from '@/lib/eventBus'
import React, { useState } from 'react'

declare global {
  namespace EventBus {
    interface EventBusEvents {
      setBottomSheetContent: [React.ReactNode]
      bottomSheetClosed: []
    }
  }
}

export const BottomSheetProvider = ({ children }: { children: React.ReactNode }) => {
  const [node, setNode] = useState<React.ReactNode>(null)

  useEventBusEffect('setBottomSheetContent', (node) => {
    setNode(node)
  })

  return (
    <>
      {children}

      <BottomSheet
        isOpen={!!node}
        onClose={() => {
          eventBus.emit('bottomSheetClosed')
          setNode(null)
        }}
      >
        {node}
      </BottomSheet>
    </>
  )
}
