import { useEffect } from 'react'

export const useBackButtonNavigate = () => {
  useEffect(() => {
    const onBack = () => {
      history.back()
    }

    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(onBack)

    return () => {
      Telegram.WebApp.BackButton.hide()
      Telegram.WebApp.BackButton.offClick(onBack)
    }
  }, [])
}
