import { usePromocodeEnterMutation } from '@/api/api'
import { CardButton } from '@/components/ui/card-button'
import { useRef } from 'react'

type EnterCodeProps = {
  type: 'key' | 'promocode'
  onSuccess?: () => void
}

export const EnterCode = ({ type, onSuccess }: EnterCodeProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { mutate: enterPromocode, isPending } = usePromocodeEnterMutation()

  const handleSendCode = () => {
    const code = inputRef.current?.value.toUpperCase()

    if (code) {
      enterPromocode(
        { code },
        {
          onSuccess() {
            onSuccess?.()
          },
        }
      )
    }
  }

  return (
    <div className="flex-1 p-4 flex flex-col gap-4 justify-start items-center">
      <div className="pt-12 pb-2">Enter your {type}</div>

      <input
        ref={inputRef}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        inputMode="text"
        autoFocus
        type="text"
        placeholder={type.toUpperCase()}
        className="text-center px-2 py-1 border border-[#9b9b9b] rounded-xl uppercase outline-none"
      />

      <CardButton className="text-lg" onClick={handleSendCode} disabled={isPending}>
        Enter
      </CardButton>
    </div>
  )
}
