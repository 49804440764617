import { EnterCode } from '@/components/EnterCode'
import { MainHeader } from '@/components/MainHeader'
import { useBackButtonNavigate } from '@/hooks/useBackButtonNavigate'
import { showAlert } from '@/providers/AlertProvider'
import { useLocation } from 'wouter'

export const EnterCodeScreen = () => {
  const [_, navigate] = useLocation()

  useBackButtonNavigate()

  const onSuccess = () => {
    navigate('/profile')
    showAlert('Promocode applied', { type: 'success' })
  }

  return (
    <div className="flex-1 p-4 flex flex-col gap-4 justify-start items-center">
      <MainHeader />

      <EnterCode type="promocode" onSuccess={onSuccess} />
    </div>
  )
}
