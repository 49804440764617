import { cn } from '@/lib/utils'
import { ChevronRight } from 'lucide-react'

type Props = {
  name: string
  icon: React.ReactNode
  onClick?: () => void
  noChevron?: boolean
  children?: React.ReactNode
}

export function SettingsItem({ name, icon, onClick, noChevron, children }: Props) {
  return (
    <div
      className={cn(
        'p-4 flex items-center gap-2 bg-[#141414] border-b border-b-[#FFFFFF05] last:border-b-0 transition-opacity',
        'first:rounded-t-[20px] last:rounded-b-[20px]',
        onClick && 'active:opacity-80'
      )}
      onClick={onClick}
    >
      <div className="size-5 flex justify-center items-center">{icon}</div>

      <div className="mr-auto font-Onest">{name}</div>

      {children}

      {!noChevron && <ChevronRight size={20} className="opacity-20" />}
    </div>
  )
}
