import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'
import { LEAGUES, League } from '@/data.app'
import { cn } from '@/lib/utils'
import * as React from 'react'
import { useEffect } from 'react'
import InfoIcon from '@/assets/infoIcon.svg?react'

type Props = {
  initialIndex: number
  onChange: (index: number) => void
  onInfoClick: () => void
}

export const LeagueCarousel = ({ initialIndex, onChange, onInfoClick }: Props) => {
  const [api, setApi] = React.useState<CarouselApi>()

  useEffect(() => {
    api?.scrollTo(initialIndex, true)
  }, [api, initialIndex])

  React.useEffect(() => {
    if (!api) {
      return
    }

    const onSelect = () => {
      onChange(api.selectedScrollSnap())
    }

    api.on('select', onSelect)

    return () => {
      api.off('select', onSelect)
    }
  }, [api, onChange])

  return (
    <Carousel className="-mt-[60px] -mb-[40px] " opts={{}} setApi={setApi}>
      <CarouselContent className="">
        {LEAGUES.map((league) => (
          <CarouselItem key={league.name}>
            <LeagueCard league={league} onInfoClick={onInfoClick} />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  )
}

type LeagueCardProps = {
  league: League
  onInfoClick: () => void
}

const LeagueCard = ({ league, onInfoClick }: LeagueCardProps) => {
  return (
    <div className="relative flex-1 h-[260px] pb-11 flex flex-col justify-end items-center">
      <div
        style={{
          background: `radial-gradient(circle, ${league.gradient[0]} 0%, transparent 55%)`,
          backgroundSize: '400px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className="absolute inset-0 opacity-40"
      ></div>
      <img
        src={league.infoImage}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[140px] max-w-min"
      />
      <div className="flex items-center gap-1 z-10">
        <span
          className={cn(
            'text-[20px] font-medium',
            Array.isArray(league.titleColor) && 'bg-clip-text'
          )}
          style={{
            color: Array.isArray(league.titleColor) ? 'transparent' : league.color,
            textShadow: `0 0 10px ${
              Array.isArray(league.titleColor) ? league.titleColor[0] : league.color
            }`,
            backgroundImage: Array.isArray(league.titleColor)
              ? `linear-gradient(90deg, ${league.titleColor[0]} 0%, ${league.titleColor[1]} 100%)`
              : undefined,
          }}
        >
          {league.name}
        </span>
        <button className="-m-4 p-4" onClick={onInfoClick}>
          <InfoIcon className="size-5" />
        </button>
      </div>
    </div>
  )
}
