import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const buttonVariants = cva('relative inline-flex justify-center items-center gap-2.5', {
  variants: {
    variant: {
      default:
        'bg-accent/30 shadow-[inset_0_-2px_0] shadow-accent transition-[box-shadow,transform] active:shadow-[inset_0_0_0] active:shadow-accent after:absolute after:inset-0 after:shadow after:rounded-[inherit] after:pointer-events-none active:translate-y-px font-medium',
      secondary: ' bg-[#FFFFFF0A] active:bg-[#FFFFFF1A] transition-colors',
    },
    size: {
      default: 'px-[30px] py-3 rounded-[14px]',
      lg: 'px-[30px] py-4 rounded-[18px]',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
