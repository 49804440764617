import {
  CurrentUserQueryResponse,
  QueryKeys,
  getSessionResult,
  useCurrentUserQuery,
  useSessionRegisterMutation,
} from '@/api/api'
import TimerIcon from '@/assets/timer.svg?react'
import { EnergyScale } from '@/components/EnergyScale'
import { GameResultsModal } from '@/components/GameResultsModal'
import { Spinner } from '@/components/spinner'
import { eventBus, useEventBusEffect } from '@/lib/eventBus'
import { formatCoinsToShort } from '@/lib/utils'
import { showAlert } from '@/providers/AlertProvider'
import { queryClient } from '@/providers/tanstackQueryProvider'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion, useAnimation } from 'motion/react'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useParams, useSearch } from 'wouter'
import { z } from 'zod'

const setSessionIdAction = z.object({
  type: z.literal('setSessionId'),
  payload: z.object({
    sessionId: z.string(),
  }),
})

const timeUpdateAction = z.object({
  type: z.literal('timeUpdate'),
  payload: z.object({
    time: z.string().refine((s) => /^\d{2}:\d{2}$/.test(s), {
      message: 'Invalid time format',
    }),
  }),
})

const changeCoinsAction = z.object({
  type: z.literal('changeCoins'),
  payload: z.object({
    coins: z.number(),
  }),
})

const hapticImpactAction = z.object({
  type: z.literal('hapticImpact'),
  payload: z.object({
    style: z.enum(['light', 'medium', 'heavy']),
  }),
})

const endGameAction = z.object({
  type: z.literal('endGame'),
})

const iframeActionSchema = z.union([
  setSessionIdAction,
  timeUpdateAction,
  changeCoinsAction,
  endGameAction,
  hapticImpactAction,
])

export function GameScreen() {
  const params = useParams()
  const searchParams = useSearch()
  const gameId = +params.gameId!
  const gameMode = searchParams.replace('gameMode=', '')

  const timeRef = useRef<HTMLDivElement>(null)
  const [_, navigate] = useLocation()
  const sessionIdRef = useRef<string | null>(null)

  useEffect(() => {
    Telegram.WebApp.enableClosingConfirmation()
    return () => {
      Telegram.WebApp.disableClosingConfirmation()
    }
  }, [])

  useEffect(() => {
    const onBack = () => {
      navigate('/')
    }

    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(onBack)

    return () => {
      Telegram.WebApp.BackButton.hide()

      Telegram.WebApp.BackButton.offClick(onBack)
    }
  }, [navigate])

  const qc = useQueryClient()

  const { data: currentUser } = useCurrentUserQuery()
  const balance = currentUser?.balance ?? 0

  const controls = useAnimation()

  const changeCoins = useCallback(
    (coins: number) => {
      qc.setQueryData<CurrentUserQueryResponse>(
        [QueryKeys.currentUser],
        (prev) => prev && { ...prev, balance: (prev.balance ?? 0) + coins }
      )

      const animations = [
        () =>
          controls.start({
            rotateY: [0, 360],
            transition: { duration: 0.6, repeat: 0 },
          }),
        () =>
          controls.start({
            rotateX: [0, 360],
            transition: { duration: 0.6, repeat: 0 },
          }),
        () =>
          controls.start({
            scale: [1, 0.9, 1.1, 0.9, 1],
            transition: { duration: 0.6, repeat: 0 },
          }),
      ]

      const randomIndex = Math.floor(Math.random() * animations.length)

      animations[randomIndex]?.()
    },
    [controls, qc]
  )

  const {
    data: sessionRegisterData,
    mutate: sessionRegisterMutate,
    isPending,
  } = useSessionRegisterMutation()

  // const sessionId = sessionRegisterData?.sessionId

  const restartGame = useCallback(() => {
    if (gameId !== -1) {
      sessionRegisterMutate(
        { gameId, gameMode },
        {
          onError() {
            navigate('/')
          },
        }
      )
    }
  }, [gameId, navigate, sessionRegisterMutate])

  useEventBusEffect('bottomSheetClosed', () => {
    navigate('/')
  })

  useEffect(() => {
    restartGame()

    return () => {
      eventBus.emit('setBottomSheetContent', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const validated = iframeActionSchema.safeParse(event.data)

      if (validated.success) {
        if (validated.data.type === 'setSessionId') {
          const { sessionId } = validated.data.payload
          sessionIdRef.current = sessionId
        } else if (validated.data.type === 'timeUpdate') {
          const { time } = validated.data.payload
          if (timeRef.current) {
            timeRef.current.innerText = time
          }
        } else if (validated.data.type === 'changeCoins') {
          const { coins } = validated.data.payload
          changeCoins(coins)
        } else if (validated.data.type === 'hapticImpact') {
          const { style } = validated.data.payload

          Telegram.WebApp.HapticFeedback.impactOccurred(style)
        } else if (validated.data.type === 'endGame') {
          getSessionResult()
            .then((data) => {
              queryClient.invalidateQueries({ queryKey: [QueryKeys.currentUser] })
              queryClient.invalidateQueries({ queryKey: [QueryKeys.leaguePlayers] })

              const sessionId = sessionRegisterData?.sessionId

              const score = sessionId ? data[sessionId] : null

              if (typeof score === 'number') {
                eventBus.emit(
                  'setBottomSheetContent',
                  <GameResultsModal results={{ coins: score }} onRestart={restartGame} />
                )
              }
            })
            .catch(() => {
              showAlert('Error while finish game', { type: 'error' })
            })
        }
      } else {
        console.log('invalid data', event.data)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [changeCoins, restartGame, sessionRegisterData?.sessionId])

  // const _gameUrl = sessionRegisterData && new URL(sessionRegisterData.url)
  // initDataRaw && _gameUrl?.searchParams.set('userToken', initDataRaw)
  // _gameUrl && (_gameUrl.host = GAME_BASE_URL.replace('https://', ''))
  // const gameUrl = _gameUrl?.href

  const gameUrl =
    sessionRegisterData && sessionRegisterData.url + `&userToken=${Telegram.WebApp.initData}`

  return (
    <div className="flex-1 mt-4 mx-4 flex flex-col gap-4">
      <motion.div
        className="self-stretch"
        initial={{ translateX: '20%', scale: 0.9 }}
        animate={{ translateX: '0%', scale: 1 }}
        transition={{ type: 'spring', damping: 20, stiffness: 400 }}
      >
        <div className="flex gap-1 items-center justify-between self-stretch">
          <div
            style={{ perspective: 1000 }}
            className="h-9 min-w-[90px] pl-[6px] pr-[10px] flex items-center bg-[#FFFFFF14] rounded-full border border-[#FFFFFF0D]"
          >
            <motion.div
              initial={{ scale: 1 }}
              animate={controls}
              style={{
                background: `url(/assets/coin.png) no-repeat center center / 100%`,
              }}
              className="size-[24px] rounded-full z-10"
              onClick={
                import.meta.env.DEV
                  ? () => {
                      eventBus.emit(
                        'setBottomSheetContent',
                        <GameResultsModal results={{ coins: 1111 }} onRestart={restartGame} />
                      )
                    }
                  : undefined
              }
            ></motion.div>

            <div className="ml-1 font-Onest font-medium">{formatCoinsToShort(balance)}</div>
          </div>

          <div className="h-9 pl-[6px] pr-[10px] flex items-center bg-[#FFFFFF14] rounded-full border border-[#FFFFFF0D]">
            <TimerIcon />

            <div ref={timeRef} className="ml-1 font-Onest font-medium tabular-nums">
              00:00
            </div>
          </div>

          <EnergyScale disabled />
        </div>
      </motion.div>

      <div className="relative flex-1 flex flex-col ">
        {gameUrl && (
          <iframe key={gameUrl} src={gameUrl} className="rounded-3xl flex-1 self-stretch"></iframe>
        )}

        {import.meta.env.DEV && gameId === -1 && (
          <iframe src={'/game.html'} className="rounded-3xl flex-1 self-stretch"></iframe>
        )}

        <AnimatePresence>
          {isPending && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 1 } }}
              className="absolute inset-0 bg-[#000] flex items-center justify-center"
            >
              <Spinner color="rgba(255, 129, 152, 1)" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}
