import { TabBar } from '@/components/TabBar'
import { useTelegramState } from '@/state/telegram-state'
import { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'wouter'

const tabs = ['/friends', '/boosts', '/', '/leagues', '/profile'] as const

export const TabBarWrapper = () => {
  const [location, navigate] = useLocation()
  const insets = useTelegramState((s) => s.insets)

  useLayoutEffect(() => {
    document.getElementById('wrap')?.scrollTo({ top: 0 })
  }, [location])

  const handleTabClick = (tabIndex: number) => {
    const tab = tabs[tabIndex]
    navigate(tab)
    Telegram.WebApp.HapticFeedback.impactOccurred('medium')
  }

  const isTabBarHidden = location.startsWith('/games/')

  const activeTab =
    location === '/' ? 2 : tabs.findIndex((tab) => tab !== '/' && location.startsWith(tab))

  return (
    <>
      <div style={{ minHeight: `${isTabBarHidden ? Math.max(16, insets.bottom) : 88}px` }} />
      {createPortal(
        <div
          className="fixed left-4 right-4 transition-[bottom] duration-500 z-10"
          style={{ bottom: `${isTabBarHidden ? -70 : Math.max(16, insets.bottom)}px` }}
        >
          <TabBar activeTab={activeTab} onTabClick={handleTabClick} />
        </div>,
        document.body
      )}
    </>
  )
}
