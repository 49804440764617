import premModalPng from '@/assets/prem-modal.png'
import { BottomSheetClose, BottomSheetGrabber } from '@/components/BottomSheet'
import { Button } from '@/components/ui/button'
import { eventBus } from '@/lib/eventBus'
import PremiumNumbersSvg from './premiumNumbers.svg?react'

export const PremiumInfoModal = () => {
  return (
    <div className="px-4 pb-8 flex flex-col gap-4">
      <div className="rounded-[26px] shadow-2xl shadow-background">
        <div
          className="relative h-[306px] bg-[#141414] rounded-[20px] overflow-hidden flex flex-col"
          style={{
            backgroundImage: `url(${premModalPng})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <BottomSheetClose />

          <BottomSheetGrabber />

          <PremiumNumbersSvg className="mt-5 mx-auto" />

          <div className="p-4 flex-1 flex flex-col justify-between items-center z-10">
            <div className="text-2xl font-semibold ">Premium friends</div>
            <div className="text-center text-sm font-medium font-Onest ">
              Each next invited friend with telegram premium brings you more BrainsCoins
            </div>
            <Button
              variant="secondary"
              className="font-semibold self-stretch"
              onClick={() => {
                eventBus.emit('setBottomSheetContent', null)
              }}
            >
              Good!
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
