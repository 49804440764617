import { create } from 'zustand'

type State = {
  isMultiplayer: boolean
}

type Actions = {
  setIsMultiplayer: (isMultiplayer: boolean) => void
}

export const useIsMultiplayer = create<State & Actions>((set) => ({
  isMultiplayer: false,
  setIsMultiplayer: (isMultiplayer: boolean) => set({ isMultiplayer }),
}))
