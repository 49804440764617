import { useHandler } from '@/hooks/common/useHandler'
import { useEffect, useRef } from 'react'

export const Timer = ({ toDate, onEnd }: { toDate: Date; onEnd: () => void }) => {
  const ref = useRef<HTMLSpanElement>(null)

  const onEndHandler = useHandler(onEnd)

  useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current) {
        if (new Date().getTime() < toDate.getTime()) {
          ref.current.innerText = dateToTimeLeftText(toDate)
        } else {
          ref.current.innerText = ''
          onEndHandler()
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [toDate, onEndHandler])

  return <span ref={ref}>{dateToTimeLeftText(toDate)}</span>
}

function dateToTimeLeftText(date: Date) {
  const time = getTimerToDate(date)
  const text = `${time.hours.toString().padStart(2, '0')}:${time.minutes
    .toString()
    .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`

  return time.days ? `${time.days}:${text}` : text
}

// return time to date in future
function getTimerToDate(date: Date) {
  const now = new Date()
  const totalSeconds = Math.floor((date.getTime() - now.getTime()) / 1000)
  const days = Math.floor(totalSeconds / (3600 * 24))
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60
  return { days, hours, minutes, seconds }
}
