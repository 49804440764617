import { cn } from '@/lib/utils'
import { motion } from 'motion/react'
import { ComponentProps } from 'react'

export const CardButton = ({ children, ...props }: ComponentProps<typeof motion.button>) => {
  return (
    <motion.button
      whileTap={!props.disabled ? { scale: 0.9 } : undefined}
      {...props}
      className={cn(
        'min-w-[78px] h-[31px] px-6 bg-[#fff]/5 rounded-[22px] border border-accent justify-center items-center flex transition-opacity disabled:opacity-20 text-[#fff] text-xs font-medium font-Onest',
        props.className
      )}
    >
      {children}
    </motion.button>
  )
}
