import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getFullNameOrUsername(user: any): string | undefined {
  if (!user) return undefined

  if (user?.firstName || user?.firstname) {
    return `${user.firstName || user.firstname} ${user.lastName || user.lastname || ''}`
  }

  return user.username
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

// format n000 to nK n000000 to nM n000000000 to nB
export const formatCoinsToShort = (number: number) => {
  if (number < 1000) return number.toString()
  if (number < 1000000) return `${(number / 1000).toFixed(1)}K`.replace('.0', '')
  if (number < 1000000000) return `${(number / 1000000).toFixed(1)}M`.replace('.0', '')
  return `${(number / 1000000000).toFixed(1)}B`.replace('.0', '')
}

// divide by space every 3 digits
export const formatCoinsToLong = (number: number, withCommas = false) => {
  const n = Math.floor(number).toString()
  return n.replace(/\B(?=(\d{3})+(?!\d))/g, withCommas ? ',' : ' ')
}

export const formatCoinsToLongWithCommas = (number: number) => {
  return formatCoinsToLong(number, true)
}

export const isDevApp = import.meta.env.DEV || import.meta.env.MODE === 'development'
