import { useEffect, useRef } from 'react'

export function useSaved<T>(value: T) {
  const ref = useRef<T>()

  useEffect(() => {
    if (value !== undefined && value !== null) {
      ref.current = value
    }
  }, [value])

  return value ?? ref.current
}
