import { useCurrentUserQuery } from '@/api/api'
import boostIcon from '@/assets/boost.png'
import ResultCup from '@/assets/game-result-cup.png'
import { BottomSheetClose, BottomSheetGrabber } from '@/components/BottomSheet'
import { Spinner } from '@/components/spinner'
import { Button } from '@/components/ui/button'
import { eventBus } from '@/lib/eventBus'
import { useLocation } from 'wouter'

type GameResultsModalProps = {
  onRestart: () => void
  results: {
    coins: number
  }
}

export const GameResultsModal = ({ onRestart, results }: GameResultsModalProps) => {
  const [_, navigate] = useLocation()

  const { data: user, isFetchedAfterMount, isFetching } = useCurrentUserQuery()

  return (
    <div className="px-4 pb-8 flex flex-col gap-4">
      <div className="rounded-[26px] shadow-2xl shadow-background">
        <div className="relative bg-[#141414] rounded-[26px] flex flex-col overflow-hidden">
          <BottomSheetClose onClick={() => navigate('/')} />

          <div className="absolute w-[120%] h-[150%] left-1/2 -translate-x-1/2 top-[-348px] opacity-60 rounded-[50%] bg-accent " />

          <div className="px-4 pb-4 flex flex-col items-center gap-4 z-10 rounded-[26px] backdrop-blur-[100px]">
            <BottomSheetGrabber />

            <img src={ResultCup} className="mt-6 size-[112px] object-contain z-10" />

            <div className="text-center text-2xl font-semibold">Game results</div>

            <div className="mb-6 flex gap-6 items-center text-white">
              <div className="flex items-center gap-2">
                <img className="w-6 h-6" src="/assets/coin.png" />

                <div className="text-base font-medium">+{results.coins}</div>
              </div>

              <div className="flex gap-2">
                <img className="w-[18px] h-[26px]" src={boostIcon} />

                {!isFetchedAfterMount && isFetching ? (
                  <div className="flex items-center ">
                    <Spinner className="size-5" />
                  </div>
                ) : (
                  <div className="text-base font-medium ">
                    {user?.energy}/{user?.max_energy}
                  </div>
                )}
              </div>
            </div>

            <Button
              variant="secondary"
              className="font-semibold self-stretch"
              onClick={() => {
                eventBus.emit('setBottomSheetContent', null)
                onRestart()
              }}
            >
              Start again
            </Button>
          </div>
        </div>
      </div>

      <Button
        variant="secondary"
        className="bg-[#141414] font-semibold transition-colors active:bg-[#222222] z-10"
        onClick={() => {
          navigate('/')
        }}
      >
        Other games
      </Button>
    </div>
  )
}
