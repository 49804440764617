import { useCurrentUserQuery } from '@/api/api'
import { MainHeader } from '@/components/MainHeader'
import { NoEnergyModal } from '@/components/NoEnergyModal'
import NumberTicker from '@/components/magicui/number-ticker'
import { GAMES, MULTIPLAYER_GAMES } from '@/data.app'
import { eventBus } from '@/lib/eventBus'
import { cn, formatCoinsToLong } from '@/lib/utils'
import { useIsMultiplayer } from '@/state/isMultiplayer'
import { AnimatePresence, motion } from 'motion/react'
import { useEffect, useRef } from 'react'
import { useLocation } from 'wouter'

export function MainTab() {
  const [_, navigate] = useLocation()

  const { isMultiplayer, setIsMultiplayer } = useIsMultiplayer()

  const { data: currentUser } = useCurrentUserQuery()

  const balance = currentUser?.balance ?? 0

  const handleGameClick = (gameId: number, gameMode: string) => {
    if (currentUser?.energy) {
      navigate(`/games/${gameId}?gameMode=${gameMode}`)
    } else {
      eventBus.emit('setBottomSheetContent', <NoEnergyModal />)
    }
  }

  const isFirstRender = useRef(true)
  useEffect(() => {
    isFirstRender.current = false
  }, [])

  const games = isMultiplayer ? MULTIPLAYER_GAMES : GAMES

  return (
    <div className="m-4 flex flex-col gap-4">
      <MainHeader />

      <div className="self-center h-[60px] flex items-center">
        <div
          style={{
            background: `url(/assets/coin.png) no-repeat center center / 100%`,
          }}
          className="size-[36px] rounded-full shadow-[0_0_30px] shadow-accent"
        ></div>

        {/* <img src="/assets/hall.svg" className="size-[36px]" /> */}

        <div className="ml-3 font-Onest font-bold text-[40px]">
          <NumberTicker value={balance} formattingFunction={formatCoinsToLong} />
        </div>
      </div>

      <div className="relative p-1 self-stretch bg-[#FFFFFF14] border border-[#FFFFFF0D] rounded-full">
        <div className="absolute top-0 -translate-y-1/2 right-1 py-0.5 px-2 bg-[#FF819833] rounded-full text-[14px] font-bold font-Onest text-accent backdrop-blur">
          {MULTIPLAYER_GAMES.length ? 'ready' : 'soon'}
        </div>

        <div className="relative flex h-[40px] items-center">
          <div
            className={cn(
              'absolute h-full w-1/2 bg-[#FF81981A] rounded-full transition-transform duration-300 pointer-events-none',
              isMultiplayer && 'translate-x-full'
            )}
          ></div>

          <button
            className={cn(
              'flex-1 h-full font-medium transition-opacity duration-300',
              isMultiplayer && 'opacity-50'
            )}
            onClick={() => setIsMultiplayer(false)}
          >
            Singleplayer
          </button>
          <button
            className={cn(
              'flex-1 h-full font-medium transition-opacity duration-300',
              !isMultiplayer && 'opacity-50'
            )}
            onClick={MULTIPLAYER_GAMES.length ? () => setIsMultiplayer(true) : undefined}
          >
            Multiplayer
          </button>
        </div>
      </div>

      <div className="relative" style={{ height: `${games.length * (69 + 16)}px` }}>
        <AnimatePresence>
          {games.map((game, i) => (
            <motion.button
              key={game.id}
              data-game-id={game.id}
              whileTap={{ scale: 0.9 }}
              initial={{ translateX: isFirstRender.current ? '-40%' : '-100%', scale: 0.9 }}
              animate={{
                translateX: '0%',
                scale: 1,
                transition: { type: 'spring', damping: 20, stiffness: 400, delay: i * 0.03 },
              }}
              exit={{
                translateX: '100%',
                scale: 0.9,
                transition: { type: 'tween', delay: i * 0.03 },
              }}
              className={cn(
                'flex absolute left-0 right-0 items-center justify-between self-stretch px-6 h-[69px] rounded-[22px] overflow-hidden '
              )}
              style={{
                background: game.image
                  ? `#141414 url(${game.image}) no-repeat right center / contain`
                  : 'linear-gradient(to right, #141414 15%, rgb(149 80 91))',
                top: `${i * (69 + 16)}px`,
              }}
              onClick={() =>
                handleGameClick(game.id, isMultiplayer ? 'multiplayer' : 'singleplayer')
              }
              disabled={import.meta.env.DEV ? undefined : game.id === -1}
            >
              <span className="text-lg font-medium z-10">{game.name}</span>
            </motion.button>
          ))}
        </AnimatePresence>
      </div>
    </div>
  )
}
