import { create } from 'zustand'

export const useTelegramState = create<{
  insets: { top: number; right: number; bottom: number; left: number }
}>()(() => ({
  // @ts-expect-error
  insets: Telegram.WebApp.safeAreaInset,
}))

// @ts-expect-error
Telegram.WebApp.onEvent('safeAreaChanged', () => {
  useTelegramState.setState({
    // @ts-expect-error
    insets: Telegram.WebApp.safeAreaInset,
  })
})
