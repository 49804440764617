import { useCurrentUserQuery, useEntitiesLeaguesQuery } from '@/api/api'
import { LeagueProgress } from '@/components/LeagueProgress'
import NumberTicker from '@/components/magicui/number-ticker'
import { League, LEAGUES } from '@/data.app'
import { cn, formatCoinsToLongWithCommas } from '@/lib/utils'
import { useMemo } from 'react'

type LeagueProgressBlockProps = {
  selectedLeague: League
}

function fixValue(value: number) {
  if (value % 10 === 9) {
    return value + 1
  }
  return value
}

const animationConfig = {
  bounce: 0,
  duration: 600,
}

export const LeagueProgressBlock = ({ selectedLeague }: LeagueProgressBlockProps) => {
  const { data: entitiesLeagues } = useEntitiesLeaguesQuery()

  const { data: currentUser } = useCurrentUserQuery()

  const currentLeague = useMemo(() => {
    return LEAGUES?.find((l) => l.id === currentUser?.league_id)
  }, [currentUser?.league_id])

  const balance = currentUser?.balance ?? 0

  const { from, to, value } = useMemo(() => {
    const index = entitiesLeagues?.findIndex((l) => l.id === selectedLeague.id)

    if (index === undefined || index < 0) {
      return {
        from: 0,
        to: 0,
        value: 0,
      }
    }

    const from = entitiesLeagues?.[index - 1]?.coinCeiling ?? 0
    const to = entitiesLeagues?.[index]?.coinCeiling ?? (from && fixValue(from) * 10) ?? Infinity

    return {
      from: fixValue(from),
      to: fixValue(to),
      value: balance > to ? 100 : balance < from ? 0 : ((balance - from) / (to - from)) * 100,
    }
  }, [balance, entitiesLeagues, selectedLeague.id])

  return (
    <div className="flex flex-col gap-3">
      <LeagueProgress
        value={value}
        style={{
          backgroundImage: `linear-gradient(90deg, ${selectedLeague.gradient[0]} 0%, ${selectedLeague.gradient[1]} 100%)`,
        }}
      />

      <div className="flex justify-between px-1">
        <div className="flex-1 text-xs font-medium opacity-50">
          <NumberTicker
            animationConfig={animationConfig}
            formattingFunction={formatCoinsToLongWithCommas}
            className="right-auto"
            value={from}
          />
        </div>

        {currentLeague && (
          <span
            className={cn(
              'text-xs font-medium',
              Array.isArray(currentLeague.titleColor) && 'bg-clip-text'
            )}
            style={{
              color: Array.isArray(currentLeague.titleColor) ? 'transparent' : currentLeague.color,
              textShadow: `0 0 10px ${
                Array.isArray(currentLeague.titleColor)
                  ? currentLeague.titleColor[0]
                  : currentLeague.color
              }`,
              backgroundImage: Array.isArray(currentLeague.titleColor)
                ? `linear-gradient(90deg, ${currentLeague.titleColor[0]} 0%, ${currentLeague.titleColor[1]} 100%)`
                : undefined,
            }}
          >
            {formatCoinsToLongWithCommas(balance)}
          </span>
        )}

        <div className="flex-1 text-right text-xs font-medium opacity-50">
          <NumberTicker
            animationConfig={animationConfig}
            className="right-auto"
            value={to}
            formattingFunction={formatCoinsToLongWithCommas}
          />
        </div>
      </div>
    </div>
  )
}
