import { useCurrentUserQuery, useLeaguePlayersQuery } from '@/api/api'
import KubokIcon from '@/assets/kubok.svg?react'
import { LeagueCarousel } from '@/components/LeagueCarousel'
import { LeagueInfoModal } from '@/components/LeagueInfoModal'
import { LeagueProgressBlock } from '@/components/LeagueProgressBlock'
import { MainHeader } from '@/components/MainHeader'
import { Spinner } from '@/components/spinner'
import { LEAGUES } from '@/data.app'
import { eventBus } from '@/lib/eventBus'
import { cn, formatCoinsToShort, getFullNameOrUsername } from '@/lib/utils'
import { useTelegramState } from '@/state/telegram-state'
import { AnimatePresence, motion } from 'motion/react'
import { useMemo, useState } from 'react'

export function LeagueTab() {
  const { data: currentUser, isLoading: isCurrentUserLoading } = useCurrentUserQuery()

  const insets = useTelegramState((s) => s.insets)

  const initialLeagueIndex = Math.max(
    LEAGUES.findIndex((l) => l.id === currentUser?.league_id),
    0
  )

  const [leagueIndex, setLeagueIndex] = useState(initialLeagueIndex)

  const selectedLeague = LEAGUES[leagueIndex]

  const { data: leaguePlayers, isLoading: isLeaguePlayersLoading } = useLeaguePlayersQuery()

  const playersList = useMemo(() => {
    if (!leaguePlayers) {
      return undefined
    }

    const selectedLeagueId = LEAGUES[leagueIndex].id

    const list = leaguePlayers.filter(
      (l) => l.league_id === selectedLeagueId && +l.id !== currentUser?.id
    )

    if (currentUser?.league_id === selectedLeagueId) {
      list.push(currentUser)
    }

    return list.sort((a, b) => b.balance - a.balance)
  }, [leaguePlayers, leagueIndex, currentUser])

  const onInfoClick = () => {
    eventBus.emit('setBottomSheetContent', <LeagueInfoModal league={selectedLeague} />)
  }

  if (isCurrentUserLoading || isLeaguePlayersLoading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <Spinner />
      </div>
    )
  }
  if (!currentUser) {
    return null
  }

  return (
    <div className="m-4 flex-1 flex flex-col">
      <MainHeader className="z-10" />

      <motion.div
        initial={{ scale: 0.7 }}
        animate={{ scale: 1 }}
        transition={{ type: 'spring', damping: 20, stiffness: 400 }}
      >
        <LeagueCarousel
          initialIndex={initialLeagueIndex}
          onChange={setLeagueIndex}
          onInfoClick={onInfoClick}
        />
      </motion.div>

      <div className="my-[18px] ">
        <LeagueProgressBlock selectedLeague={selectedLeague} />
      </div>

      <div className="relative flex-1 grid">
        <AnimatePresence>
          <div key={leagueIndex} className="min-w-0 row-start-1 col-end-1 flex flex-col gap-[14px]">
            {playersList?.map((player, index) => {
              const isCurrentUser = +player.id === Telegram.WebApp.initDataUnsafe.user?.id
              return (
                <div
                  key={index}
                  className={cn(isCurrentUser && 'sticky z-10')}
                  style={{
                    perspective: 1000,
                    top: isCurrentUser ? 26 + insets.top : 0,
                    bottom: isCurrentUser ? 72 + insets.bottom : 0,
                  }}
                >
                  <motion.div
                    {...(index < 10 || isCurrentUser
                      ? {
                          initial: { rotateX: 90, scale: 0.97 },
                          animate: {
                            transition: {
                              duration: 0.4,
                              delay: Math.min(index, 8) * 0.05 + 0.15,
                              easings: ['easeOut'],
                            },
                            rotateX: 0,
                            scale: 1,
                          },
                          exit: {
                            rotateX: -90,
                            scale: 0.97,
                            transition: {
                              duration: 0.15,
                              delay: Math.min(index, 8) * 0.05,
                              ease: [0, 0, 1, 0.5],
                            },
                          },
                        }
                      : undefined)}
                  >
                    <div
                      className={cn(
                        'px-4 py-3 flex gap-3 justify-between items-center bg-[#1e1a1af0] rounded-2xl border border-[#FFFFFF0D]',
                        isCurrentUser && 'bg-[#1e1a1af0] border-accent/50'
                      )}
                    >
                      <div className="flex-1 min-w-0 font-medium flex gap-2 items-center">
                        <div className={cn(index < 3 ? 'text-accent' : 'opacity-50')}>
                          #{index > 99 ? '100+' : index + 1}
                        </div>

                        <div className="min-w-0 opacity-50 truncate">
                          {getFullNameOrUsername(player)}
                        </div>

                        {index < 3 && <KubokIcon className="shrink-0 size-[20px]" />}
                      </div>

                      <div className=" font-medium ">{formatCoinsToShort(player.balance)}</div>
                    </div>
                  </motion.div>
                </div>
              )
            })}
          </div>

          {playersList?.length === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
            >
              <EmptyLeaguePlaceholder />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

const EmptyLeaguePlaceholder = () => {
  return (
    <div className="flex flex-col items-center gap-5">
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M34.9375 39.5417H15.0625C14.1875 39.5417 13.3958 38.9792 13.1042 38.1667L4.43751 13.8958C3.75001 11.9583 5.95835 10.3125 7.60418 11.5L15.9375 17.4583C17.0417 18.25 18.625 17.7708 19.1042 16.5L23.0417 6C23.7083 4.1875 26.2708 4.1875 26.9375 6L30.875 16.5C31.3542 17.7917 32.9167 18.25 34.0417 17.4583L42.375 11.5C44.0417 10.3125 46.2292 11.9792 45.5417 13.8958L36.875 38.1667C36.6042 38.9792 35.8125 39.5417 34.9375 39.5417Z"
          fill="#FF8198"
        />
        <path
          d="M35.4166 45.8335H14.5833C13.7291 45.8335 13.0208 45.1252 13.0208 44.271C13.0208 43.4168 13.7291 42.7085 14.5833 42.7085H35.4166C36.2708 42.7085 36.9791 43.4168 36.9791 44.271C36.9791 45.1252 36.2708 45.8335 35.4166 45.8335Z"
          fill="#FF8198"
        />
        <path
          d="M30.2084 30.729H19.7917C18.9375 30.729 18.2292 30.0207 18.2292 29.1665C18.2292 28.3123 18.9375 27.604 19.7917 27.604H30.2084C31.0625 27.604 31.7709 28.3123 31.7709 29.1665C31.7709 30.0207 31.0625 30.729 30.2084 30.729Z"
          fill="#FF8198"
        />
      </svg>

      <div className="w-[260px] opacity-50 text-center text-white text-sm font-medium font-Onest">
        No one has ever possessed the power of this league, you can be the first
      </div>
    </div>
  )
}
