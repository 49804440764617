import { BottomSheetClose, BottomSheetGrabber } from '@/components/BottomSheet'
import { Button } from '@/components/ui/button'
import { DAILY_REWARDS } from '@/data.app'
import { eventBus } from '@/lib/eventBus'
import { cn } from '@/lib/utils'

type DailyRewardModalProps = {
  currentIndex: number
  onClaim: () => void
}

export const DailyRewardModal = ({ currentIndex, onClaim }: DailyRewardModalProps) => {
  return (
    <div className="pb-8 px-4 flex flex-col gap-4">
      <div className="rounded-[26px] shadow-2xl shadow-background">
        <div className="relative bg-[#141414] rounded-[26px] flex flex-col  overflow-hidden">
          <BottomSheetClose />

          <div className="absolute w-[120%] h-[180%] left-1/2 -translate-x-1/2 top-[-348px] opacity-60 rounded-[50%] bg-accent " />

          <div className="px-4 pb-4 flex flex-col gap-4 self-stretch z-10 rounded-[26px] backdrop-blur-[100px]">
            <BottomSheetGrabber />

            <div className="mt-1 text-center text-2xl font-semibold">Daily Bonus</div>

            <div className="my-2 grid grid-cols-4 gap-2">
              {DAILY_REWARDS.map((reward, index) => (
                <div
                  key={index}
                  className={cn(
                    'h-[88px] p-2 border border-[#ff8197] rounded-[13px] flex flex-col items-center justify-between',
                    currentIndex === index ? 'bg-[#ff8197]' : 'bg-[transparent]'
                  )}
                >
                  <div className="text-center text-xs font-medium font-Onest">Day {index + 1}</div>

                  <img className="w-6 h-6" src="/assets/coin.png" />

                  <div className="text-center text-white text-xs font-medium font-Onest">
                    {reward / 1000}K
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Button
        variant="secondary"
        className="bg-[#141414] font-semibold transition-colors active:bg-[#222222]"
        onClick={() => {
          eventBus.emit('setBottomSheetContent', null)
          onClaim()
        }}
      >
        Claim
      </Button>
    </div>
  )
}
