if (import.meta.env.DEV && !window.Telegram.WebApp.initData) {
  location.hash =
    'tgWebAppData=' +
    'user%3D%257B%2522id%2522%253A323875116%252C%2522first_name%2522%253A%2522Andrey%2522%252C%2522last_name%2522%253A%2522P%2522%252C%2522username%2522%253A%2522an3park%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522allows_write_to_pm%2522%253Atrue%252C%2522photo_url%2522%253A%2522https%253A%255C%252F%255C%252Ft.me%255C%252Fi%255C%252Fuserpic%255C%252F320%255C%252Fu37lkPRO1kihgyfCiEDiwfat5p3YHabpmyaOvVXpHi8.svg%2522%257D%26chat_instance%3D309410603144987267%26chat_type%3Dchannel%26auth_date%3D1732311175%26signature%3DoExuhaKcuiuwakqXEGGLf0OuOazGEJkKhadSk189DvDCz3yykGPtaNoWJ8Mjbpic6QfkjFYcASoFpOuoGMFAAg%26hash%3Da4287687712e3d19dbb931822d2372b26d5cec5f98cd50c1050a8dd32a3e7367&tgWebAppVersion=7.10&tgWebAppPlatform=macos&tgWebAppThemeParams=%7B%22bg_color%22%3A%22%2318222d%22%2C%22secondary_bg_color%22%3A%22%23131415%22%2C%22header_bg_color%22%3A%22%23131415%22%2C%22hint_color%22%3A%22%23b1c3d5%22%2C%22accent_text_color%22%3A%22%232ea6ff%22%2C%22text_color%22%3A%22%23ffffff%22%2C%22subtitle_text_color%22%3A%22%23b1c3d5%22%2C%22section_separator_color%22%3A%22%23213040%22%2C%22section_bg_color%22%3A%22%2318222d%22%2C%22section_header_text_color%22%3A%22%23b1c3d5%22%2C%22bottom_bar_bg_color%22%3A%22%23213040%22%2C%22link_color%22%3A%22%2362bcf9%22%2C%22button_text_color%22%3A%22%23ffffff%22%2C%22destructive_text_color%22%3A%22%23ef5b5b%22%2C%22button_color%22%3A%22%232ea6ff%22%7D'
  location.reload()
}

import { ATheme } from '@/components/a-theme.tsx'
import { Preloader } from '@/components/Preloader'
import { TabBarWrapper } from '@/components/TabBarWrapper'
import { isFeatureEnabled } from '@/lib/isFeatureEnabled.ts'
import { isDevApp } from '@/lib/utils.ts'
import { Providers } from '@/providers/providers'
import { Navigation } from '@/router'
import { BlockLayout } from '@/screens/BlockLayout.tsx'
import '@fontsource-variable/onest'
import '@fontsource-variable/red-hat-mono'
import posthog from 'posthog-js'
import ReactDOM from 'react-dom/client'
import './index.css'
import './lib/sentry'

let erudaInitialized = false

function initEruda() {
  if (erudaInitialized) return
  erudaInitialized = true

  const script = document.createElement('script')
  script.src = 'https://cdn.jsdelivr.net/npm/eruda'
  document.body.append(script)
  script.onload = function () {
    const script = document.createElement('script')
    script.innerText = 'eruda.init()'
    document.body.append(script)
    setTimeout(() => {
      console.log(Telegram.WebApp.initData)
    }, 100)
  }
}

if (isDevApp) {
  initEruda()
}

posthog.onFeatureFlags(() => {
  if (isFeatureEnabled('dev')) {
    initEruda()
  }
})

try {
  // @ts-ignore
  Telegram.WebApp.requestFullscreen()
} catch (_) {
  //
}
Telegram.WebApp.expand()
Telegram.WebApp.disableVerticalSwipes()
Telegram.WebApp.setHeaderColor('#000000')

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Providers>
    <BlockLayout>
      <Preloader>
        <ATheme />
        <Navigation />
        <TabBarWrapper />
      </Preloader>
    </BlockLayout>
  </Providers>
)
