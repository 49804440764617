import posthog from 'posthog-js'

const AdController = window.Adsgram!.init({ blockId: '5160' })

export async function showAd(placement: string) {
  return AdController.show()
    .then((result) => {
      console.log('Ad watched', placement, result)
      posthog.capture('ad_watched', { placement, result })
      return result
    })
    .catch((error) => {
      console.error('Ad error', placement, error)
      posthog.capture('ad_error', { placement, error })
      throw error
    })
}
