import Logo from '@/assets/logo.svg?react'
import BlockWebQrCode from './blockWebQrCode.svg?react'

type BlockLayoutProps = {
  children: React.ReactNode
}

export const BlockLayout = ({ children }: BlockLayoutProps) => {
  if (Telegram.WebApp.platform.includes('web')) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center">
        <Logo />

        <div className="mt-[18px] mb-[61px] w-[254px] text-center text-white text-xl font-medium">
          The application is not available from a telegram web
        </div>

        <BlockWebQrCode />
      </div>
    )
  }

  return children
}
