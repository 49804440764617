import T1 from '@/assets/tabs/1.svg?react'
import T2 from '@/assets/tabs/2.svg?react'
import T3 from '@/assets/tabs/3.svg?react'
import T4 from '@/assets/tabs/4.svg?react'
import T5 from '@/assets/tabs/5.svg?react'
import A1 from '@/assets/tabs/1a.svg?react'
import A2 from '@/assets/tabs/2a.svg?react'
import A3 from '@/assets/tabs/3a.svg?react'
import A4 from '@/assets/tabs/4a.svg?react'
import A5 from '@/assets/tabs/5a.svg?react'
import { cn } from '@/lib/utils'

type TabBarProps = {
  activeTab: number | null
  className?: string
  onTabClick: (tabIndex: number) => void
}

export const TabBar = ({ activeTab, className, onTabClick }: TabBarProps) => {
  return (
    <div
      className={cn(
        'flex p-1.5 bg-[#141414]/90 rounded-[20px] justify-evenly [&_svg]:pointer-events-none backdrop-blur',
        className
      )}
    >
      <button
        className={cn(activeTab === 0 && 'animate-tabbar-icon')}
        onClick={() => onTabClick(0)}
      >
        {activeTab === 0 ? <A1 /> : <T1 />}
      </button>

      <button
        className={cn(activeTab === 1 && 'animate-tabbar-icon')}
        onClick={() => onTabClick(1)}
      >
        {activeTab === 1 ? <A2 /> : <T2 />}
      </button>

      <button
        className={cn(activeTab === 2 && 'animate-tabbar-icon')}
        onClick={() => onTabClick(2)}
      >
        {activeTab === 2 ? <A3 /> : <T3 />}
      </button>

      <button
        className={cn(activeTab === 3 && 'animate-tabbar-icon')}
        onClick={() => onTabClick(3)}
      >
        {activeTab === 3 ? <A4 /> : <T4 />}
      </button>

      <button
        className={cn(activeTab === 4 && 'animate-tabbar-icon')}
        onClick={() => onTabClick(4)}
      >
        {activeTab === 4 ? <A5 /> : <T5 />}
      </button>
    </div>
  )
}
