import { isDevApp } from '@/lib/utils'

export type League = {
  id: number
  name: string
  // image: string
  // miniImage: string
  infoImage: string
  color: string
  gradient: string[]
  titleColor: string | string[]
}

export const LEAGUES: League[] = [
  {
    id: 1,
    name: 'Bronze League',
    // image: './assets/league/area/bronze.webp',
    // miniImage: './assets/league/mini/bronze.png',
    infoImage: './assets/league/140_3x/bronze.png',
    color: '#F3E7DB',
    gradient: ['#F3E7DB', '#BCA488'],
    titleColor: '#F3E7DB',
  },
  {
    id: 2,
    name: 'Silver League',
    // image: './assets/league/area/silver.webp',
    // miniImage: './assets/league/mini/silver.png',
    infoImage: './assets/league/140_3x/silver.png',
    color: '#AEAEAE',
    gradient: ['#AEAEAE', '#919191'],
    titleColor: '#AEAEAE',
  },
  {
    id: 3,
    name: 'Gold League',
    // image: './assets/league/area/gold1.webp',
    // miniImage: './assets/league/mini/gold1.png',
    infoImage: './assets/league/140_3x/gold.png',
    color: '#FFBB37',
    gradient: ['#FFBB37', '#CA8602'],
    titleColor: '#FFBB37',
  },
  {
    id: 4,
    name: 'Platinum League',
    // image: './assets/league/area/platinum.webp',
    // miniImage: './assets/league/mini/platinum.png',
    infoImage: './assets/league/140_3x/platinum.png',
    color: '#FFFFFF',
    gradient: ['#FFFFFF', '#838383'],
    titleColor: '#FFFFFF',
  },
  {
    id: 5,
    name: 'Olive League',
    // image: './assets/league/area/olive.webp',
    // miniImage: './assets/league/mini/olive.png',
    infoImage: './assets/league/140_3x/olive.png',
    color: '#B3CE27',
    gradient: ['#B3CE27', '#7A8B20'],
    titleColor: '#B3CE27',
  },
  {
    id: 6,
    name: 'Emerald League',
    // image: './assets/league/area/emerald.webp',
    // miniImage: './assets/league/mini/emerald.png',
    infoImage: './assets/league/140_3x/emerald.png',
    color: '#3CFF9A',
    gradient: ['#3CFF9A', '#2FAE6C'],
    titleColor: '#3CFF9A',
  },
  {
    id: 7,
    name: 'Brilliant League',
    // image: './assets/league/area/brilliant.webp',
    // miniImage: './assets/league/mini/brilliant.png',
    infoImage: './assets/league/140_3x/brilliant.png',
    color: '#1FC3C1',
    gradient: ['#3CDCFF', '#0086A9'],
    titleColor: '#1FC3C1',
  },
  {
    id: 8,
    name: 'JuniorBrain League',
    // image: './assets/league/area/juniorbrain.webp',
    // miniImage: './assets/league/mini/juniorbrain.png',
    infoImage: './assets/league/140_3x/junior.png',
    color: '#FF9EAF',
    gradient: ['#FF9EAF', '#DE7D8E'],
    titleColor: ['#FF9EAF', '#DE7D8E'],
  },
  {
    id: 9,
    name: 'MiddleBrain League',
    // image: './assets/league/area/middlebrain.webp',
    // miniImage: './assets/league/mini/middlebrain.png',
    infoImage: './assets/league/140_3x/middle.png',
    color: '#DB9EFF',
    gradient: ['#DB9EFF', '#B281CE'],
    titleColor: ['#DB9EFF', '#B281CE'],
  },
  {
    id: 10,
    name: 'SeniorBrain League',
    // image: './assets/league/area/seniorbrain.webp',
    // miniImage: './assets/league/mini/seniorbrain.png',
    infoImage: './assets/league/140_3x/senior.png',
    color: '#FF4B27',
    gradient: ['#FF4B27', '#F13D19'],
    titleColor: ['#FF4B27', '#F13D19'],
  },
  {
    id: 11,
    name: 'LeadBrain League',
    // image: './assets/league/area/leadbrain.webp',
    // miniImage: './assets/league/mini/leadbrain.png',
    infoImage: './assets/league/140_3x/lead.png',
    color: '#F9346C',
    gradient: ['#F9346C', '#FF4B27'],
    titleColor: ['#F9346C', '#FF4B27'],
  },
  {
    id: 12,
    name: 'Megabrain League',
    // image: './assets/league/area/megabrain.webp',
    // miniImage: './assets/league/mini/megabrain.png',
    infoImage: './assets/league/140_3x/mega.png',
    color: '#FF8198',
    gradient: ['#FF8198', '#3D42D0'],
    titleColor: ['#FF8198', '#3D42D0'],
  },
]

export type Boost = {
  id: number
  title: string
  description: string
  icon: string
  postfix?: string
}

export const BOOSTS: Boost[] = [
  {
    id: 1,
    title: 'Restore Energy',
    description: 'You have replenished your energy, the games are waiting',
    icon: './assets/boost/energy3.png',
  },
  {
    id: 2,
    title: 'Boost Brains',
    description: 'Double your braces, go ahead and play!',
    postfix: 'x2',
    icon: './assets/boost/brainboost.png',
  },
]

export type Game = {
  id: number
  name: string
  image?: string
}

export const GAMES: Game[] = [
  {
    id: 1,
    name: 'Eye of God',
    image: './assets/games/eye.png',
  },
  {
    id: 2,
    name: 'Memory Booster',
    image: './assets/games/mem.png',
  },
  {
    id: 3,
    name: 'Brainy Paw',
    image: './assets/games/hand.png',
  },
  {
    id: -1,
    name: 'Coming Soon',
  },
]

export const MULTIPLAYER_GAMES: Game[] = isDevApp
  ? [
      {
        id: 5,
        name: 'Brainy Paw',
        image: './assets/games/hand.png',
      },
    ]
  : []

export type Task = {
  id: number
  name: string
  description: string
  icon: string
}

export const DAILY_REWARDS = [1000, 2500, 5000, 10000, 25000, 50000, 100000]
