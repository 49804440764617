import { ApiError, TaskType, useReferralsQuery, useTaskClaimMutation } from '@/api/api'
import bbqIcon from '@/assets/tasks/bbq.jpg'
import beeIcon from '@/assets/tasks/bee.png'
import BoostIcon from '@/assets/tasks/boost.svg?react'
import BrainIcon from '@/assets/tasks/brain.svg?react'
import Brain2Icon from '@/assets/tasks/brain2.svg?react'
import bumpIcon from '@/assets/tasks/bump.png'
import DurovIcon from '@/assets/tasks/durovrun.jpg'
import GemseeIcon from '@/assets/tasks/gemsee.jpg'
import lizzardIcon from '@/assets/tasks/lizz.jpg'
import MeercatIcon from '@/assets/tasks/meerkat.jpg'
import SpinIcon from '@/assets/tasks/real_spin.webp'
import TeaIcon from '@/assets/tasks/tea.jpg'
import TgIcon from '@/assets/tasks/tg.svg?react'
import TwitterIcon from '@/assets/tasks/twitter.svg?react'
import { DailyRewardModal } from '@/components/DailyRewardModal'
import { MainHeader } from '@/components/MainHeader'
import { Spinner } from '@/components/spinner'
import { CardButton } from '@/components/ui/card-button'
import { useBackButtonNavigate } from '@/hooks/useBackButtonNavigate'
import { useTasks } from '@/hooks/useTasks'
import { eventBus } from '@/lib/eventBus'
import { isFeatureEnabled } from '@/lib/isFeatureEnabled'
import { cn, formatCoinsToShort } from '@/lib/utils'
import { showAlert } from '@/providers/AlertProvider'
import { motion } from 'motion/react'
import { useLayoutEffect, useRef, useState } from 'react'

export function TasksTab() {
  const { tasks, isReadyForClaim, isTasksLoading } = useTasks()

  useBackButtonNavigate()

  const isRussian = Telegram.WebApp.initDataUnsafe.user?.language_code === 'ru'

  // const { data: currentUser } = useCurrentUserQuery()

  const { mutate } = useTaskClaimMutation()

  const { data: referrals } = useReferralsQuery()

  const [pendingTask, setPendingTask] = useState<TaskType | null>(null)

  const claimTask = async (task: TaskType) => {
    setPendingTask(task)

    if (task === 'twitter' && !(await Telegram.WebApp.CloudStorage.getItem('twitter_clicked'))) {
      Telegram.WebApp.CloudStorage.setItem('twitter_clicked', '1')

      Telegram.WebApp.openTelegramLink('https://twitter.com/brainscoin_off')

      return setPendingTask(null)
    }

    if (task === 'bumpPlayed' && !(await Telegram.WebApp.CloudStorage.getItem('bumpPlayed'))) {
      Telegram.WebApp.CloudStorage.setItem('bumpPlayed', '1')

      Telegram.WebApp.openTelegramLink('/MMproBump_bot?start=ref_561617722')

      return setPendingTask(null)
    }
    if (
      task === 'beeVersePlayed' &&
      !(await Telegram.WebApp.CloudStorage.getItem('beeVersePlayed'))
    ) {
      Telegram.WebApp.CloudStorage.setItem('beeVersePlayed', '1')

      Telegram.WebApp.openTelegramLink('/bee_verse_bot?start=843760608')

      return setPendingTask(null)
    }
    if (
      task === 'lizArtsPlayed' &&
      !(await Telegram.WebApp.CloudStorage.getItem('lizArtsPlayed'))
    ) {
      Telegram.WebApp.CloudStorage.setItem('lizArtsPlayed', '1')

      Telegram.WebApp.openTelegramLink('/lizartsworld_bot/game?startapp=666c52cc2d9d0b0aacc88282')

      return setPendingTask(null)
    }
    if (
      task === 'bbqCoinPlayed' &&
      !(await Telegram.WebApp.CloudStorage.getItem('bbqCoinPlayed'))
    ) {
      Telegram.WebApp.CloudStorage.setItem('bbqCoinPlayed', '1')

      Telegram.WebApp.openTelegramLink('/BBQcoin_bot/BBQcoin?startapp=r_7242935953')

      return setPendingTask(null)
    }
    if (
      task === 'meerkatPlayed' &&
      !(await Telegram.WebApp.CloudStorage.getItem('meerkatPlayed'))
    ) {
      Telegram.WebApp.CloudStorage.setItem('meerkatPlayed', '1')

      Telegram.WebApp.openTelegramLink('/meerkat_coin_bot/app?startapp=7242935953')

      return setPendingTask(null)
    }
    if (task === 'gemseePlayed' && !(await Telegram.WebApp.CloudStorage.getItem('gemseePlayed'))) {
      Telegram.WebApp.CloudStorage.setItem('gemseePlayed', '1')

      Telegram.WebApp.openTelegramLink('/gemsee_bot/game?startapp=3b9acde9')

      return setPendingTask(null)
    }
    if (
      task === 'durovRunPlayed' &&
      !(await Telegram.WebApp.CloudStorage.getItem('durovRunPlayed'))
    ) {
      Telegram.WebApp.CloudStorage.setItem('durovRunPlayed', '1')

      Telegram.WebApp.openTelegramLink('/durovrunbot/run?startapp=runId7242935953')

      return setPendingTask(null)
    }
    if (
      task === 'realSpinPlayed' &&
      !(await Telegram.WebApp.CloudStorage.getItem('realSpinPlayed'))
    ) {
      Telegram.WebApp.CloudStorage.setItem('realSpinPlayed', '1')

      Telegram.WebApp.openTelegramLink('/RealSpin_bot')

      return setPendingTask(null)
    }
    if (
      task === 'teaFarmPlayed' &&
      !(await Telegram.WebApp.CloudStorage.getItem('teaFarmPlayed'))
    ) {
      Telegram.WebApp.CloudStorage.setItem('teaFarmPlayed', '1')

      Telegram.WebApp.openTelegramLink('/TeaFarmTownBot/game?startapp=s_brainscoin')

      return setPendingTask(null)
    }

    mutate(
      { task },
      {
        onSuccess() {
          // if (task === 'channel_boost') {
          //   eventBus.emit('setBottomSheetContent', <ChannelBoostTaskModal onClaim={() => {}} />)
          //   return
          // }

          showAlert('Task claimed', { type: 'success' })
        },
        onError(error) {
          if (task === 'telegram') {
            Telegram.WebApp.openTelegramLink('/brainscoin')
          } else if (task === 'bumpSubscription') {
            Telegram.WebApp.openTelegramLink('/+KEHBJlA4gqs2NWIy')
          } else if (task === 'lizArtsSubscription') {
            Telegram.WebApp.openTelegramLink('/+iTquo0wabC4zMWJi')
          } else if (task === 'beeVerseSubscription') {
            Telegram.WebApp.openTelegramLink('/beeverse_ann')
          } else if (task === 'boost') {
            if (error instanceof ApiError) {
              if (error.status === 400) {
                showAlert('You do not provide boosts', { type: 'error' })
              }
            }
          } else if (task === 'emoji') {
            if (error instanceof ApiError) {
              if (error.status === 400) {
                showAlert('Profile emoji is not set', { type: 'error' })
              }
            }
          } else if (task === 'ruChatSubscription') {
            if (error instanceof ApiError) {
              if (error.status === 400) {
                Telegram.WebApp.openTelegramLink('/+72KrpIGzyyA0Y2Uy')
              }
            }
          } else if (task === 'enChatSubscription') {
            if (error instanceof ApiError) {
              if (error.status === 400) {
                Telegram.WebApp.openTelegramLink('/+_4OrmQfqWh4zMTYy')
              }
            }
          } else {
            if (error instanceof ApiError) {
              showAlert('Error while claiming task', { type: 'error' })
            }
          }
        },
        onSettled() {
          setPendingTask(null)
        },
      }
    )
  }

  const handleJoinChat = () => {
    claimTask(isRussian ? 'ruChatSubscription' : 'enChatSubscription')
  }

  let rowIndex = 0

  return (
    <div className="m-4 flex flex-col gap-4">
      <MainHeader />

      <div className="text-xl font-medium">Tasks</div>

      {isTasksLoading && (
        <div className="flex-1 flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {tasks && isReadyForClaim && (
        <>
          <TaskCard
            index={rowIndex++}
            icon={<TgIcon />}
            name="Follow telegram"
            description={`+ ${formatCoinsToShort(100000)} BC`}
            isReadyForClaim={isReadyForClaim.telegram}
            onClick={() => claimTask('telegram')}
            disabled={pendingTask === 'telegram'}
          />
          <TaskCard
            index={rowIndex++}
            icon={<TwitterIcon />}
            name="Follow X"
            description={`+ ${formatCoinsToShort(100000)} BC`}
            isReadyForClaim={isReadyForClaim.twitter}
            onClick={() => claimTask('twitter')}
            disabled={pendingTask === 'twitter'}
          />
          <TaskCard
            index={rowIndex++}
            icon={<BrainIcon />}
            name="Invite 3 friends"
            description={
              ((referrals?.length || 0) <= 3 ? `${referrals?.length || 0}/3 friends, ` : '') +
              `+ ${formatCoinsToShort(350000)} BC`
            }
            isReadyForClaim={isReadyForClaim.friends}
            onClick={() => claimTask('referrals')}
            disabled={(referrals && referrals.length < 3) || pendingTask === 'referrals'}
          />
          <TaskCard
            index={rowIndex++}
            icon={<Brain2Icon />}
            name="Daily Bonus"
            description={`+${formatCoinsToShort(tasks.dailyReward.reward)} BC`}
            isReadyForClaim={isReadyForClaim.dailyReward}
            onClick={() =>
              eventBus.emit(
                'setBottomSheetContent',
                <DailyRewardModal
                  currentIndex={tasks.dailyReward.level - 1}
                  onClaim={() => claimTask('daily')}
                />
              )
            }
            disabled={pendingTask === 'daily'}
          />
          <TaskCard
            index={rowIndex++}
            icon={<BoostIcon />}
            name="Boost channel"
            timerTo={tasks.boostTask.available_at}
            description={`+ ${formatCoinsToShort(100000)} BC`}
            isReadyForClaim={isReadyForClaim.boost}
            onClick={() => claimTask('boost')}
            disabled={pendingTask === 'boost'}
          />
          <TaskCard
            index={rowIndex++}
            icon={<div className="text-2xl">🧠</div>}
            name="Profile emoji"
            timerTo={tasks.emojiTask.available_at}
            description={`+ ${formatCoinsToShort(100000)} BC`}
            isReadyForClaim={isReadyForClaim.emoji}
            onClick={() => claimTask('emoji')}
            disabled={pendingTask === 'emoji'}
          />
          {isFeatureEnabled('joinChat') && (
            <TaskCard
              index={rowIndex++}
              icon={<Brain2Icon />}
              name="Join our chat"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.joinChat}
              onClick={handleJoinChat}
              disabled={
                pendingTask === 'ruChatSubscription' || pendingTask === 'enChatSubscription'
              }
            />
          )}
          {isFeatureEnabled('bump') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={bumpIcon} className="object-contain size-8 rounded-full" />}
              name="Follow Bump"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.bumpSubscription}
              onClick={() => claimTask('bumpSubscription')}
              disabled={pendingTask === 'bumpSubscription'}
            />
          )}
          {isFeatureEnabled('bump') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={bumpIcon} className="object-contain size-8 rounded-full" />}
              name="Play Bump"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.bumpPlayed}
              onClick={() => claimTask('bumpPlayed')}
              disabled={pendingTask === 'bumpPlayed'}
            />
          )}
          {isFeatureEnabled('bee') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={beeIcon} className="object-contain size-8 rounded-full" />}
              name="Follow BeeVerse"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.beeVerseSubscription}
              onClick={() => claimTask('beeVerseSubscription')}
              disabled={pendingTask === 'beeVerseSubscription'}
            />
          )}
          {isFeatureEnabled('bee') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={beeIcon} className="object-contain size-8 rounded-full" />}
              name="Play BeeVerse"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.beeVersePlayed}
              onClick={() => claimTask('beeVersePlayed')}
              disabled={pendingTask === 'beeVersePlayed'}
            />
          )}
          {isFeatureEnabled('lizarts') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={lizzardIcon} className="object-contain size-8 rounded-full" />}
              name="Follow LizArts"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.lizArtsSubscription}
              onClick={() => claimTask('lizArtsSubscription')}
              disabled={pendingTask === 'lizArtsSubscription'}
            />
          )}
          {isFeatureEnabled('lizarts') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={lizzardIcon} className="object-contain size-8 rounded-full" />}
              name="Play LizArts"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.lizArtsPlayed}
              onClick={() => claimTask('lizArtsPlayed')}
              disabled={pendingTask === 'lizArtsPlayed'}
            />
          )}
          {isFeatureEnabled('bbq') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={bbqIcon} className="object-contain size-8 rounded-full" />}
              name="Play BBQCoin"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.bbqCoinPlayed}
              onClick={() => claimTask('bbqCoinPlayed')}
              disabled={pendingTask === 'bbqCoinPlayed'}
            />
          )}
          {isFeatureEnabled('meerkat') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={MeercatIcon} className="object-contain size-8 rounded-full" />}
              name="Play Meerkat"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.meerkatPlayed}
              onClick={() => claimTask('meerkatPlayed')}
              disabled={pendingTask === 'meerkatPlayed'}
            />
          )}
          {isFeatureEnabled('gemsee') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={GemseeIcon} className="object-contain size-8 rounded-full" />}
              name="Play Gemsee"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.gemseePlayed}
              onClick={() => claimTask('gemseePlayed')}
              disabled={pendingTask === 'gemseePlayed'}
            />
          )}
          {isFeatureEnabled('durovrun') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={DurovIcon} className="object-contain size-8 rounded-full" />}
              name="Play Durov Run"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.durovRunPlayed}
              onClick={() => claimTask('durovRunPlayed')}
              disabled={pendingTask === 'durovRunPlayed'}
            />
          )}
          {isFeatureEnabled('real_spin') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={SpinIcon} className="object-contain size-8 rounded-full" />}
              name="Play Real Spin"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.realSpinPlayed}
              onClick={() => claimTask('realSpinPlayed')}
              disabled={pendingTask === 'realSpinPlayed'}
            />
          )}
          {isFeatureEnabled('tea_farm') && (
            <TaskCard
              index={rowIndex++}
              icon={<img src={TeaIcon} className="object-contain size-8 rounded-full" />}
              name="Play Tea Farm"
              description={`+ ${formatCoinsToShort(100000)} BC`}
              isReadyForClaim={isReadyForClaim.teaFarmPlayed}
              onClick={() => claimTask('teaFarmPlayed')}
              disabled={pendingTask === 'teaFarmPlayed'}
            />
          )}
        </>
      )}
    </div>
  )
}

type TaskCardProps = {
  name: string
  description: string
  index: number
  onClick: () => void
  isReadyForClaim: boolean | undefined
  disabled?: boolean
  icon: React.ReactNode
  timerTo?: string | null
}

const TaskCard = ({
  name,
  description,
  index,
  onClick,
  isReadyForClaim,
  disabled,
  icon,
  timerTo,
}: TaskCardProps) => {
  const ref = useRef<HTMLSpanElement>(null)

  const [isTimer, setIsTimer] = useState(timerTo ? new Date(timerTo) > new Date() : false)

  useLayoutEffect(() => {
    if (!timerTo) return

    const handler = () => {
      if (!ref.current) return

      const delta = new Date(timerTo).getTime() - new Date().getTime()
      setIsTimer(delta > 0)
      if (delta <= 0) {
        ref.current.innerText = 'Claim'
        return
      }

      // const days = Math.floor(delta / (1000 * 60 * 60 * 24))
      // const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((delta % (1000 * 60)) / 1000)

      ref.current.innerText = `${minutes}:${seconds.toString().padStart(2, '0')}`
    }

    handler()
    const interval = setInterval(handler, 1000)

    return () => clearInterval(interval)
  }, [timerTo])

  return (
    <motion.div
      {...(index < 9
        ? {
            initial: { translateX: '-20%', scale: 0.9 },
            animate: { translateX: '0%', scale: 1 },
            transition: { type: 'spring', damping: 20, stiffness: 400, delay: index * 0.03 },
          }
        : undefined)}
    >
      <div className="px-4 py-3 flex gap-3 justify-start items-center bg-[#FFFFFF14] rounded-[22px] border border-[#FFFFFF0D]">
        <div className="size-[40px] flex justify-center items-center">{icon}</div>

        <div className="flex-1 flex-col gap-1">
          <div className={cn('text-sm font-medium line-clamp-1')}>{name}</div>

          <div className="text-xs opacity-50">{description}</div>
        </div>

        {isReadyForClaim ? (
          <div className="size-[31px] bg-[#FFFFFF0A] border border-[#FF819800] rounded-full flex justify-center items-center">
            <SmallSign />
          </div>
        ) : (
          <CardButton onClick={onClick} disabled={disabled || isTimer}>
            <span className="tabular-nums" ref={ref}>
              Claim
            </span>
          </CardButton>
        )}
      </div>
    </motion.div>
  )
}

function SmallSign() {
  return (
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none">
      <path
        d="M1.5 4.73096L4.03468 7.01217C4.46618 7.40052 5.13659 7.34106 5.493 6.88282L9.5 1.73096"
        stroke="#FF8198"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
