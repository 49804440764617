import { cn } from '@/lib/utils'

type SpinnerProps = {
  color?: string
  className?: string
}

export const Spinner = ({ color = '#fff', className }: SpinnerProps) => {
  return (
    <div
      role="status"
      className={cn(
        'inline-block h-8 w-8 animate-spin rounded-full border-4 align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]',
        className
      )}
      style={{ borderColor: color, borderInlineEndColor: 'transparent' }}
    >
      <span className="sr-only">Loading...</span>
    </div>
  )
}
