import { useEntitiesLeaguesQuery } from '@/api/api'
import { BottomSheetClose, BottomSheetGrabber } from '@/components/BottomSheet'
import { Button } from '@/components/ui/button'
import { League } from '@/data.app'
import { eventBus } from '@/lib/eventBus'

type LeagueInfoModalProps = {
  league: League
}

export const LeagueInfoModal = ({ league }: LeagueInfoModalProps) => {
  const { data: entitiesLeagues } = useEntitiesLeaguesQuery()

  const entityLeague = entitiesLeagues?.find((l) => l.id === league.id)

  return (
    <div className="p-4 pb-8 flex flex-col gap-4">
      <div className="rounded-[26px] shadow-2xl shadow-background">
        <div className="relative bg-[#141414] rounded-[26px] overflow-hidden">
          <div
            className="absolute w-[90%] h-[90%] left-1/2 -translate-x-1/2 top-[-158px] opacity-60 rounded-full "
            style={{
              backgroundImage: `linear-gradient(to right, ${league.gradient[0]}, ${league.gradient[1]})`,
            }}
          />

          <div className="relative flex flex-col gap-3 z-10 rounded-[26px] backdrop-blur-[100px]">
            <BottomSheetClose onClick={() => eventBus.emit('setBottomSheetContent', null)} />
            <BottomSheetGrabber />

            <img
              src={league.infoImage}
              className="my-[-30px] self-center size-[200px] object-cover z-10"
            />

            <div className=" text-center text-white text-xl font-semibold ">
              About {league.name}
            </div>

            <div className="flex flex-col gap-1 text-center">
              <div className=" text-[#ff8197] text-sm font-semibold ">Max energy</div>
              <div className=" text-white text-2xl font-bold ">{entityLeague?.maxEnergy}</div>
            </div>

            <div className="flex flex-col gap-1 text-center">
              <div className=" text-[#ff8197] text-sm font-semibold ">Max Boost Shop</div>
              <div className=" text-white text-2xl font-bold ">{entityLeague?.maxBoosts}</div>
            </div>

            <div className="pb-6 flex flex-col gap-1 text-center">
              <div className=" text-[#ff8197] text-sm font-semibold ">Recovery time energy</div>
              <div className=" text-white text-2xl font-bold lowercase">
                {entityLeague?.boostCooldown}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button
        variant="secondary"
        className="bg-[#141414] font-semibold transition-colors active:bg-[#222222]"
        onClick={() => eventBus.emit('setBottomSheetContent', null)}
        disabled={false}
      >
        Okay
      </Button>
    </div>
  )
}
