import { useCurrentUserQuery } from '@/api/api'
import Plus from '@/assets/plus.svg?react'
import LightBolt from '@/assets/tabs/2a.svg?react'
import { Progress } from '@/components/ui/progress'
import { cn } from '@/lib/utils'
import { useLocation } from 'wouter'

type Props = {
  className?: string
  disabled?: boolean
}

export function EnergyScale({ className, disabled }: Props) {
  const [_, navigate] = useLocation()

  const { data: currentUser } = useCurrentUserQuery()

  const energy = (currentUser?.energy || 0) / (currentUser?.max_energy || 0) || 0

  return (
    <button
      className={cn(
        'h-[36px] w-[138px] px-1.5 flex gap-0.5 border border-[#FFFFFF0D] rounded-full items-center bg-gradient1 bg-[length:calc(100%+2px)] bg-center',
        className
      )}
      onClick={() => navigate('/boosts')}
      disabled={disabled}
    >
      <LightBolt width={24} height={24} />
      <Progress value={energy * 100} className="flex-1" />
      <Plus width={24} height={24} />
    </button>
  )
}
