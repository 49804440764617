'use client'

import { AlertProvider } from '@/providers/AlertProvider'
import { BottomSheetProvider } from '@/providers/BottomSheet'
import { PostHogProvider } from '@/providers/postHogProvider'
import { TanstackQueryProvider } from '@/providers/tanstackQueryProvider'

const providers = [PostHogProvider, TanstackQueryProvider, AlertProvider, BottomSheetProvider]

export function Providers({ children }: { children: React.ReactNode }) {
  return providers.reduceRight((acc, Provider) => <Provider>{acc}</Provider>, children)
}
