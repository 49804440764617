import { ApiError, useCurrentUserQuery } from '@/api/api'
import Logo from '@/assets/logo.svg?react'
import { EnterCode } from '@/components/EnterCode'
import { ErrorScreen } from '@/components/ErrorScreen'
import { Spinner } from '@/components/spinner'

type PreloaderProps = {
  children: React.ReactNode
}

export const Preloader = ({ children }: PreloaderProps) => {
  const { data, isLoading, error } = useCurrentUserQuery()

  if (!data) {
    if (isLoading) {
      return (
        <div className="flex-1 flex justify-center items-center">
          <Spinner />
        </div>
      )
    }
    if (error instanceof ApiError) {
      if (error.body?.code === 1001) {
        return (
          <div className="flex-1 p-4 flex flex-col gap-4 justify-start items-center">
            <Logo />

            <EnterCode type="key" />
          </div>
        )
      }
    } else {
      console.log('not api error')
    }
    return <ErrorScreen />
  }

  return children
}
