import { BoostTab } from '@/screens/BoostTab'
import { EnterCodeScreen } from '@/screens/EnterCodeScreen'
import { FriendsTab } from '@/screens/FriendsTab'
import { GameScreen } from '@/screens/GameScreen'
import { LeagueTab } from '@/screens/LeagueTab'
import { MainTab } from '@/screens/MainTab'
import { ProfileTab } from '@/screens/ProfileTab'
import { TasksTab } from '@/screens/TasksTab'
import { useTelegramState } from '@/state/telegram-state'
import { Route, Switch } from 'wouter'

export const Navigation = () => {
  const insets = useTelegramState((s) => s.insets)
  return (
    <div className="flex-1 flex flex-col" style={{ paddingTop: `${10 + insets.top}px` }}>
      <Switch>
        <Route path="/friends" component={FriendsTab} />
        <Route path="/boosts" component={BoostTab} />
        <Route path="/" component={MainTab} />
        <Route path="/games/:gameId" component={GameScreen} />
        <Route path="/leagues" component={LeagueTab} />
        <Route path="/profile" component={ProfileTab} />
        <Route path="/profile/tasks" component={TasksTab} />
        <Route path="/profile/enter-code" component={EnterCodeScreen} />

        <Route path="*">{(params) => `404, Sorry the page ${params['*']} does not exist!`}</Route>
      </Switch>
    </div>
  )
}
