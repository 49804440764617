import { usePrevious } from '@/hooks/common/usePrevious'
import { useSaved } from '@/hooks/common/useSaved'
import { eventBus } from '@/lib/eventBus'
import { cn } from '@/lib/utils'
import { PanInfo, motion, useAnimation } from 'motion/react'
import { HTMLAttributes, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
}

export function BottomSheet({ isOpen, onClose: onCloseProp, children, className }: Props) {
  const savedChildren = useSaved(children)

  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setIsRendered(true)
    }
  }, [isOpen])

  const onAnimationComplete = (variant: string) => {
    if (variant === 'hidden') {
      setIsRendered(false)
    }
  }

  function onClose() {
    onCloseProp()
  }

  // function onOpen() {
  //   setIsOpen(true)
  // }

  // function onToggle() {
  //   setIsOpen(!isOpen)
  // }

  const prevIsOpen = usePrevious(isOpen)
  const controls = useAnimation()

  function onDragEnd(_event: any, info: PanInfo) {
    const shouldClose = info.velocity.y > 20 || (info.velocity.y >= 0 && info.point.y > 45)
    if (shouldClose) {
      controls.start('hidden')
      onClose()
    } else {
      // controls.start('visible')
      // onOpen()
    }
  }

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      controls.start('hidden')
    } else if (!prevIsOpen && isOpen) {
      controls.start('visible')
    }
  }, [controls, isOpen, prevIsOpen])

  if (!isRendered && !isOpen) {
    return null
  }

  return createPortal(
    <>
      <motion.div
        drag="y"
        onDragEnd={onDragEnd}
        initial="hidden"
        animate={controls}
        onAnimationComplete={onAnimationComplete}
        transition={{
          type: 'spring',
          damping: 40,
          stiffness: 400,
        }}
        variants={{
          visible: { y: 0 },
          hidden: { y: '100%' },
        }}
        dragConstraints={{ top: 0 }}
        dragElastic={0.2}
        className={cn('absolute bottom-0 left-0 right-0 z-30', className)}
      >
        {savedChildren}
      </motion.div>

      <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        onClick={onClose}
        className="fixed inset-0 bg-[#111]/60 z-20"
      ></motion.div>
    </>,
    document.body
  )
}

type BottomSheetCloseProps = HTMLAttributes<HTMLDivElement>

export const BottomSheetClose = (props: BottomSheetCloseProps) => {
  return (
    <div
      className="absolute top-4 right-4 cursor-pointer z-20"
      onClick={() => eventBus.emit('setBottomSheetContent', null)}
      {...props}
    >
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <circle cx="14" cy="14" r="14" fill="white" fillOpacity="0.1" />
        <path
          d="M18.2427 9.75738L9.75739 18.2427M18.2427 18.2426L9.75739 9.75732"
          stroke="white"
          strokeOpacity="0.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export const BottomSheetGrabber = () => {
  return <div className="mt-3 mx-auto w-[34px] h-1 bg-[#fff]/20 rounded-sm z-20" />
}
