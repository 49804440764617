import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { showAlert } from './AlertProvider'
import { ApiError } from '@/api/api'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60_000 * 2,
      throwOnError(error) {
        console.error(error)
        if (!(error instanceof ApiError)) {
          showAlert(String(error), { type: 'error' })
        }
        return false
      },
      retry: (failureCount, error) => {
        if (error instanceof ApiError) {
          return false
        }
        return failureCount < 3
      },
    },
    mutations: {
      onError(error) {
        console.error(error)
        if (!(error instanceof ApiError)) {
          showAlert(String(error), { type: 'error' })
        }
      },
    },
  },
})

export const TanstackQueryProvider = ({ children }: { children: React.ReactNode }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
