import { useReferralClaimMutation, useReferralsQuery } from '@/api/api'
import InfoIcon from '@/assets/infoIcon.svg?react'
import premModalPng from '@/assets/prem-modal.png'
import { MainHeader } from '@/components/MainHeader'
import { PremiumInfoModal } from '@/components/PremiumInfoModal'
import { Spinner } from '@/components/spinner'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { CardButton } from '@/components/ui/card-button'
import { BOT_APPNAME, BOT_USERNAME } from '@/constants'
import { eventBus } from '@/lib/eventBus'
import { cn, formatCoinsToShort, getFullNameOrUsername } from '@/lib/utils'
import { CheckIcon, CopyIcon, ShareIcon } from 'lucide-react'
import { motion } from 'motion/react'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

export function FriendsTab() {
  const isMobile = Telegram.WebApp.platform === 'ios' || Telegram.WebApp.platform === 'android'

  const [isCopying, setIsCopying] = useState(false)

  const inviteLink = `https://t.me/${BOT_USERNAME}/${BOT_APPNAME}?startapp=friend${Telegram.WebApp.initDataUnsafe.user?.id}`

  const inviteText = encodeURIComponent(
    'Come to Brainscoin and earn money using your skills!\nMy link will give you 50k at the start.\n\nCome in and start your journey to the top league!'
  )

  const { data: referrals, isLoading: isReferralsLoading } = useReferralsQuery()

  const nextPremiumReward = useMemo(() => {
    return Math.max(125_000, ...(referrals || []).filter((x) => x.is_premium).map((x) => x.reward))
  }, [referrals])

  const {
    mutate: claimReferral,
    isPending: isClaiming,
    variables: claimVariables,
  } = useReferralClaimMutation()

  const handleSharePress = () => {
    if (isMobile) {
      Telegram.WebApp.openTelegramLink(`https://t.me/share?text=${inviteText}&url=${inviteLink}`)
    } else {
      navigator.clipboard.writeText(inviteLink)
      setIsCopying(true)
      setTimeout(() => {
        setIsCopying(false)
      }, 1000)
    }
  }

  const handleClaimPress = (referralId: number) => {
    claimReferral({ referralId })
  }

  const handlePremiumInfoPress = () => {
    eventBus.emit('setBottomSheetContent', <PremiumInfoModal />)
  }

  return (
    <div
      className={cn(
        'm-4 flex flex-col gap-4',
        referrals?.length === 0 && 'absolute inset-0 overflow-hidden'
      )}
    >
      {createPortal(<link rel="preload" as="image" href={premModalPng} />, document.head)}
      <MainHeader />

      <div className="text-xl font-medium">Referrals</div>

      <div className="flex gap-4">
        {Array.from({ length: 2 }).map((_, index) => (
          <motion.div
            key={index}
            whileTap={index === 1 ? { scale: 0.9 } : undefined}
            onClick={index === 1 ? handlePremiumInfoPress : undefined}
            initial={{ scale: 0.7 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', damping: 20, stiffness: 400, delay: 0 }}
            className="flex-1"
          >
            <div className=" relative bg-[#fff]/10 rounded-[22px] border border-[#fff]/5 overflow-hidden">
              <div className=" p-4 h-[111px] flex flex-col justify-between ">
                <div
                  className={cn(
                    'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 w-[120px] h-[120px] rounded-full -z-10',
                    index === 1
                      ? 'bg-gradient-to-r from-[#7987ff] to-[#eb5dbb] blur-2xl'
                      : 'bg-gradient-to-tl from-[#ff8197] blur-2xl'
                  )}
                />

                <div className=" text-sm font-medium font-Onest flex gap-1.5 items-center">
                  {index === 1 ? 'Premium friends' : 'Friends'}

                  {index === 1 && <InfoIcon />}
                </div>

                <div className="flex items-center gap-1.5">
                  <div className="text-xl font-bold font-Onest">
                    +{formatCoinsToShort(index === 1 ? nextPremiumReward : 50000)}
                  </div>
                  <img className="size-5" src="/assets/coin.png" />
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      <motion.button
        className="text-left "
        initial={{ translateX: '-20%', scale: 0.9 }}
        animate={{ translateX: '0%', scale: 1 }}
        transition={{ type: 'spring', damping: 20, stiffness: 400 }}
        onClick={handleSharePress}
      >
        <div
          className={cn(
            'p-4 flex gap-3 items-center bg-[#2c1f20] rounded-2xl border border-[#333333]'
          )}
        >
          <span className="flex-1 text-sm font-medium opacity-50 --break-all">Invite friend</span>

          <div className=" size-[20px] relative">
            {isMobile ? (
              <ShareIcon className="size-[20px] opacity-50" />
            ) : (
              <>
                <CopyIcon
                  className={cn(
                    'size-[20px] opacity-50 rotate-0 scale-100 transition-all',
                    isCopying && '-rotate-90 scale-0'
                  )}
                />
                <CheckIcon
                  className={cn(
                    'absolute top-0 size-[20px] opacity-50 rotate-90 scale-0 transition-all',
                    isCopying && 'rotate-0 scale-100'
                  )}
                />
              </>
            )}
          </div>
        </div>
      </motion.button>

      {isReferralsLoading && (
        <div className="flex-1 flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {referrals?.length === 0 && <EmptyFriendsPlaceholder />}

      {referrals?.map((referral, index) => (
        <motion.div
          key={referral.id}
          {...(index < 9
            ? {
                initial: { translateX: '-20%', scale: 0.9 },
                animate: { translateX: '0%', scale: 1 },
                transition: {
                  type: 'spring',
                  damping: 20,
                  stiffness: 400,
                  delay: (index + 2) * 0.03,
                },
              }
            : undefined)}
        >
          <div className="px-4 py-3 flex gap-3 justify-start items-center bg-[#2c1f20] rounded-[22px] border border-[#333333]">
            <Avatar className="size-[40px]">
              <AvatarImage src={referral.photo_url || undefined} />
              <AvatarFallback>{referral.firstname[0]}</AvatarFallback>
            </Avatar>

            <div className={'flex-1 min-w-0'}>
              <div
                className={cn(
                  'font-medium truncate',
                  referral.is_premium
                    ? 'text-[transparent] bg-gradient-to-r from-[#7A87FF] to-[#EC5EBC] bg-clip-text'
                    : 'opacity-50'
                )}
              >
                {getFullNameOrUsername(referral)}
              </div>
            </div>

            {referral.reward_processed ? (
              <div className="flex items-center gap-1">
                <div className="font-Onest font-medium">+{formatCoinsToShort(referral.reward)}</div>

                <img className="w-5 h-5" src="/assets/coin.png" />
              </div>
            ) : (
              <CardButton
                disabled={isClaiming && claimVariables?.referralId === +referral.id}
                onClick={() => handleClaimPress(referral.id)}
              >
                Claim
              </CardButton>
            )}
          </div>
        </motion.div>
      ))}
    </div>
  )
}

const noFriendsStrings = [
  'Who is there?',
  "It's really him",
  'Is this real?',
  "I don't believe my eyes",
]
function EmptyFriendsPlaceholder() {
  return (
    <motion.div
      className="relative flex flex-col gap-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{}}
    >
      {noFriendsStrings.map((text, i) => (
        <div key={text}>
          <div
            className="px-4 py-3 flex gap-3 justify-start items-center bg-[#FFFFFF14] rounded-[22px] border border-[#FFFFFF0D]"
            style={{ opacity: (noFriendsStrings.length - i) * 0.1 }}
          >
            <div className="size-[40px] rounded-full bg-[#FFFFFF14]"></div>

            <div className="opacity-50 font-medium">{text}</div>
          </div>
        </div>
      ))}
      <div className="absolute inset-0 flex justify-center ">
        <div className="mt-14 opacity-50 text-center text-sm font-medium font-Onest">
          You haven't invited anyone yet
        </div>
      </div>
    </motion.div>
  )
}
