import { AnimatePresence, motion } from 'motion/react'
import { XCircleIcon } from 'lucide-react'
import { useInsertionEffect, useState } from 'react'

type Options = {
  type?: 'success' | 'error' | 'info'
}

let showMessage: (message: string, options?: Options) => void

export const showAlert = (message: string, options?: Options) => {
  showMessage(message, options)
}

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [messages, setMessages] = useState<
    { id: number; message: string; type?: Options['type'] }[]
  >([])

  useInsertionEffect(() => {
    showMessage = (message: string, options?: Options) => {
      setMessages((prev) => [...prev, { id: Date.now(), message, type: options?.type }])
      setTimeout(() => {
        setMessages((prev) => prev.slice(1))
      }, 4000)
    }
  }, [])

  const handleClose = (id: number) => {
    setMessages((prev) => prev.filter((message) => message.id !== id))
  }

  return (
    <>
      <div className="fixed top-4 left-4 right-4 z-50">
        <div className="relative">
          <AnimatePresence>
            {messages.map((message, index, { length }) => (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{
                  opacity: 1,
                  y: (length - 1 - index) * -10,
                  scale: 1 - (length - 1 - index) * 0.01,
                }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute left-0 right-0 bg-[#141414] rounded-lg px-4 py-2 flex items-center justify-between border border-[#222222] cursor-pointer"
                style={{
                  backgroundColor:
                    message.type === 'success'
                      ? '#10a981'
                      : message.type === 'error'
                      ? '#af4444'
                      : undefined,
                }}
                onClick={() => handleClose(message.id)}
              >
                <span className="flex-1">{message.message}</span>
                <XCircleIcon className="w-4 h-4" />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
      {children}
    </>
  )
}
